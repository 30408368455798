<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <div class="row">
      <b-overlay
        :show="overlayFlag"
        :spinner-variant="this.$overlayVariant"
        :spinner-type="this.$overlayType"
        :rounded="this.$overlayRounded"
      >
        <div class="col-lg-12">
          <div class="card">
            <form class="needs-validation" @submit.prevent="Form">
              <div class="card-body">
                <div class="row mt-2">
                  <h5 class="font-size-14 mb-1"></h5>
                  <div class="col-md-4 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="branchId">สาขา:</label>
                      <multiselect
                        disabled
                        id="branchId"
                        v-model="tooltipform.branchId"
                        :options="localData"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label for="nameTh">ประเภทของทีม </label>:
                      <multiselect
                        v-model="tooltipform.type"
                        :options="optionTeam"
                        label="name"
                        track-by="id"
                        :show-labels="false"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.type.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.type.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.type.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label for="name">ชื่อทีม:</label>
                      <b-form-input
                        v-model="tooltipform.name"
                        placeholder=""
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.name.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitform && $v.tooltipform.name.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.name.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-success">บันทึก</button>
                </div>
              </div>
            </form>

            <hr />
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="font-size-16 mb-1">สมาชิกทีม</h4>
              <div class="row mt-2">
                <div class="text-end mb-3">
                  <button v-b-modal.modalMembers class="btn btn-primary">
                    เพิ่มสมาชิก
                  </button>
                </div>
                <b-skeleton-wrapper :loading="loading">
                  <!-- skeleton  -->
                  <template #loading>
                    <b-skeleton-table></b-skeleton-table>
                  </template>
                  <tableData :fields="fields" :items="rowTeam">
                    <template #cell(sellPrice)="rowTeam">
                      {{ Number(rowTeam.item.sellPrice).toLocaleString() }}
                    </template>

                    <template #cell(index)="rowTeam">
                      {{ rowTeam.index + 1 }}
                    </template>

                    <template #cell(action)="rowTeam">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <!-- <router-link
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            title="Edit"
                            :to="{
                              name: 'edit-team',
                              params: { teamId: rowData.item.teamIdBase64 },
                            }"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link> -->

                          <a
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alert(rowTeam.item.memberId)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </tableData>

                  <br />

                  <div class="row">
                    <span
                      v-if="this.rowTeam.length === 0"
                      class="text-center font-size-16"
                      >ไม่พบรายการ</span
                    >
                  </div>
                </b-skeleton-wrapper>
              </div>
            </div>
            <b-modal
              ref="modalMembers"
              id="modalMembers"
              title="เพิ่มสมาชิก"
              hide-footer
              size="lg"
              centered
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="userId">พนักงาน:</label>
                      <multiselect
                        id="userId"
                        v-model="form.userId"
                        :options="rowUser"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                        :custom-label="customLabel"
                        @search-change="getDataUser($event)"
                        :class="{
                          'is-invalid': submitformMem && $v.form.userId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                      <div
                        v-if="submitformMem && $v.form.userId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.userId.required">{{ error }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="memberType">ประเภท:</label>
                      <multiselect
                        id="memberType"
                        v-model="form.memberType"
                        :options="memberTypeOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="id"
                        :class="{
                          'is-invalid':
                            submitformMem && $v.form.memberType.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                      <div
                        v-if="submitformMem && $v.form.memberType.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.memberType.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <button @click="FormMember" class="btn btn-success float-end">
                    บันทึก
                  </button>
                </div>
              </div>
            </b-modal>
            <hr />
          </div>
        </div>
      </b-overlay>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import tableData from "@/components/tablecomponent";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    tableData,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.team,
    meta: [
      {
        name: "description",
        content: appConfig.team,
      },
    ],
  },

  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "แก้ไขทีม",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },

        {
          text: appConfig.team,
          href: "/team",
        },
        {
          text: "แก้ไขทีม",
          active: true,
        },
      ],
      submitformMem: false,
      overlayFlag: false,
      teamId: this.$route.params.teamId,
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "memberName",
          label: "ชื่อพนักงาน",
        },
        {
          key: "memberType",
          label: "ประเภท",
        },

        {
          key: "action",
          label: "ลบ",
        },
      ],
      memberTypeOption: [
        { nameTh: "หัวหน้าทีม", id: "LED" },
        { nameTh: "สมาชิก", id: "MEM" },
      ],
      form: { userId: "", memberType: "" },
      startIndex: "",
      endIndex: "",
      loading: false,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      yearOption: [],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      rowTeam: [],
      filter: {
        nameThPros: "",
        familyNameThPros: "",
        licensePlate: "",
        regCode: "",
        familyNameTh: "",
        nameTh: "",
        regDate: "",
        paymentCode: "",
      },
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      totalPageVe: "",
      perPageVe: 10,
      currentPageVe: 1,
      totalRecordVe: "",
      pageOptionsVe: [5, 10, 25, 50, 100],

      totalPagePros: "",
      perPagePros: 10,
      currentPagePros: 1,
      totalRecordPros: "",
      pageOptionsPros: [5, 10, 25, 50, 100],
      rowUser: [],
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,000 - 19999", id: 1 },
        { nameTh: "20000-29999", id: 2 },
        { nameTh: "100000 บาทขึ้นไป", id: 3 },
      ],
      optionTeam: [{ name: "ทีมขาย", id: "SALE" }],
      mobilePhone: "",
      vehicaleOption: [],
      contactTypeOption: [],
      customerOption: [],
      prospectOption: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      index: "",
      tooltipform: {
        branchId: "",
        type: "",
        name: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    tooltipform: {
      type: {
        required,
      },
      name: {
        required,
      },
    },
    form: {
      userId: {
        required,
      },
      memberType: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.tooltipform.branchId = {
          branchId: item.branchId,
          nameTh: item.nameTh,
        };
      }
    });
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.getDataShow();
  },
  methods: {
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName != null ? familyName : ""}`;
    },
    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      let data = "";
      while (currentYear >= earliestYear) {
        data = { nameTh: currentYear };
        years.push(data);
        currentYear -= 1;
      }
      this.yearOption = years;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkMonth(data) {
      const variableOne = this.optionMonth.filter(
        (itemInArray) => itemInArray.id === data
      );
      return variableOne[0].name;
      // console.log(variableOne);
    },
    select(data) {
      this.data = data[0];
      this.tooltipform.ppCusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        ppCusId: this.data.ppCusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;

      this.tooltipform.cusId = "";
      this.$refs["modalPropect"].hide();
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : ""
      }`;
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.tooltipform.$invalid != true) {
        this.postData();
      }
    },
    FormMember() {
      this.$v.$touch();
      this.submitformMem = true;
      if (this.$v.form.$invalid != true) {
        this.postDataMember();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },
    checkType(data) {
      let name = "";
      if (data == "SALE") {
        return (name = "ทีมขาย");
      }
      return name;
    },
    getDataShow: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/team/show", {
          params: {
            teamId: atob(this.teamId),
          },
        })
        .then((response) => {
          // console.log(response);
          this.rowData = response.data.master;
          this.tooltipform.branchId = {
            nameTh: response.data.master.branchName,
            branchId: response.data.master.branchId,
          };
          this.tooltipform.type = {
            name: this.checkType(response.data.master.type),
            id: response.data.master.type,
          };
          this.tooltipform.name = response.data.master.teamName;
          this.getDataTeam();
          this.getDataUser();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataTeam: function () {
      this.loading = true;

      useNetw
        .get("api/team/member", {
          params: {
            teamId: atob(this.teamId),
          },
        })
        .then((response) => {
          // console.log(response);
          this.rowTeam = response.data.members;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataUser: function (event) {
      this.overlayFlag = true;

      useNetw
        .get("api/team/branch-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.tooltipform.branchId.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    postData: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/team/update", {
          teamId: atob(this.teamId),
          type: this.tooltipform.type.id,

          name: this.tooltipform.name,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataShow();
          // btoa(
          // this.$router.push({
          //   name: "team",
          // });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    postDataMember: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/team/member/store", {
          teamId: atob(this.teamId),
          userId: this.form.userId.userId,

          memberType: this.form.memberType.id,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.$refs["modalMembers"].hide();
          this.submitformMem = false;
          this.form.userId = "";
          this.form.memberType = "";

          // btoa(
          this.getDataTeam();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    delete: function (rowData) {
      this.Data = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/team/member/delete", {
          data: {
            memberId: this.Data,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getDataTeam();

          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData);
          }
        });
    },
    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },

    handleSearch() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getCustomer();
    },
    handleSearchVe() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getVehical();
    },
    handleSearchPros() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getProspect();
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getCustomer();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getCustomer();
    },
    handleChangePageVe(page) {
      this.currentPageVe = page;
      this.getVehical();
    },
    handlePageChangeVe(active) {
      this.perPageVe = active;
      this.currentPageVe = 1;
      this.getVehical();
    },

    handleChangePagePros(page) {
      this.currentPagePros = page;
      this.getProspect();
    },
    handlePageChangePros(active) {
      this.perPagePros = active;
      this.currentPagePros = 1;
      this.getProspect();
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}
</style>
